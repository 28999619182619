import AuthView from "@/views/auth/AuthView.vue";
import AuthEmail from "@/views/auth/AuthEmail.vue";
import AuthPassword from "@/views/auth/AuthPassword.vue";
import AuthEmailSent from "@/views/auth/AuthEmailSent.vue";
import AuthEmailConfirm from "@/views/auth/AuthEmailConfirm.vue";
import AuthEmailConfirming from "@/views/auth/AuthEmailConfirming.vue";
import AuthPasswordNew from "@/views/auth/AuthPasswordNew.vue";
import AuthErrorConfirm from "@/views/auth/AuthErrorConfirm.vue";
import AuthSuccessConfirm from "@/views/auth/AuthSuccessConfirm.vue";
import { authPasswordGuard, authTokenPresentGuard, redirectIfLoggedIn, signInWithUrlToken } from "./guards/guards";
import { fetchQuestionnaires, questionnairesMandatoryGuard } from "@/router/guards/questionnaires";
import AuthErrorMagic from "@/views/auth/AuthErrorMagic.vue";
import AuthSigningIn from "@/views/auth/AuthSigningIn.vue";
import AuthSignInConfirm from "@/views/auth/AuthSignInConfirm.vue";
import { RouteName, RouteNameMenu } from "@/types/AuthTypes";
import QuestionnaireMain from "@/views/questionnaire/QuestionnaireMain.vue";
import ContainerMain from "@/views/menu-views/ContainerMain.vue";
import FormulaMain from "@/views/menu-views/FormulaMain.vue";
import SettingsMain from "@/views/menu-views/SettingsMain.vue";
import CheckupResults from "@/components/blood-drop/CheckupResults.vue";
import ProfileInformation from "@/components/settings/ProfileInformation.vue";
import BioniqSettings from "@/components/settings/BioniqSettings.vue";
import DownloadMobileApp from "@/views/DownloadMobileApp.vue";
import MagicLinkMobile from "@/components/layout/MagicLinkMobile.vue";

const mainMenuRoutes = [
  {
    path: "",
    name: RouteNameMenu.MENU_ANALYTICS,
    component: CheckupResults,
  },
  {
    path: "formula",
    name: RouteNameMenu.MENU_FORMULA,
    component: FormulaMain,
  },
  {
    path: "settings",
    name: RouteNameMenu.MENU_SETTINGS,
    component: SettingsMain,
    redirect: { name: RouteName.SETTINGS_BIONIQ },
    children: [
      {
        path: "profile-info",
        name: RouteName.SETTINGS_PROFILE,
        component: ProfileInformation,
      },
      {
        path: "bioniq-settings",
        name: RouteName.SETTINGS_BIONIQ,
        component: BioniqSettings,
      },
    ],
  },
];

const emailCallbackRoutes = [
  {
    path: "/sign-up",
    name: RouteName.AUTH_SIGNUP_CONFIRMING,
    component: AuthEmailConfirming,
    beforeEnter: authTokenPresentGuard,
    meta: {
      signUp: true,
      emailLink: true,
    },
  },
  {
    path: "/confirm-email",
    name: RouteName.AUTH_EMAIL_CONFIRMING,
    component: AuthEmailConfirming,
    beforeEnter: authTokenPresentGuard,
    meta: {
      confirmEmail: true,
      emailLink: true,
    },
  },
  {
    path: "/reset-password",
    name: RouteName.AUTH_PASS_NEW,
    component: AuthPasswordNew,
    beforeEnter: authTokenPresentGuard,
    meta: {
      emailLink: true,
    },
  },
  {
    path: "/sign-in",
    name: RouteName.AUTH_SIGNING_IN,
    component: AuthSigningIn,
    beforeEnter: authTokenPresentGuard,
    meta: {
      emailLink: true,
    },
  },
];

const authenticationChildrenRoutes = [
  {
    path: "",
    name: RouteName.AUTH_EMAIL,
    component: AuthEmail,
  },
  {
    path: "password",
    name: RouteName.AUTH_PASSWORD,
    component: AuthPassword,
    beforeEnter: authPasswordGuard,
  },
  {
    path: "email-fp",
    name: RouteName.AUTH_EMAIL_FP,
    component: AuthEmail,
    meta: {
      forgotPasswordScreen: true,
    },
  },
  {
    path: "email-change",
    name: RouteName.AUTH_EMAIL_CHANGE,
    component: AuthEmail,
    meta: {
      changeEmailScreen: true,
    },
  },
  {
    path: "email-sent",
    name: RouteName.AUTH_EMAIL_SENT,
    component: AuthEmailSent,
  },
  {
    path: "email-confirm",
    name: RouteName.AUTH_EMAIL_CONFIRM,
    component: AuthEmailConfirm,
  },
  {
    path: "sign-in-confirm",
    name: RouteName.AUTH_SIGN_IN_CONFIRM,
    component: AuthSignInConfirm,
  },
  {
    path: "error-confirm",
    name: RouteName.AUTH_ERROR_CONFIRM,
    component: AuthErrorConfirm,
  },
  {
    path: "error-magic",
    name: RouteName.AUTH_ERROR_MAGIC,
    component: AuthErrorMagic,
  },
  {
    path: "success-confirm",
    name: RouteName.AUTH_SUCCESS_CONFIRM,
    component: AuthSuccessConfirm,
  },
];

export const routes = [
  ...emailCallbackRoutes,
  {
    path: "/auth",
    component: AuthView,
    beforeEnter: redirectIfLoggedIn,
    children: authenticationChildrenRoutes,
  },
  {
    path: "/questionnaires",
    component: QuestionnaireMain,
    meta: {
      requiresAuth: true,
    },
    name: RouteName.QUESTIONNAIRE_MAIN,
    beforeEnter: fetchQuestionnaires,
    children: [], // * This route will have dynamically assigned children routes
  },
  {
    path: "/download-mobile-app",
    name: RouteName.DOWNLOAD_MOBILE_APP,
    component: DownloadMobileApp,
  },
  {
    path: "/spike",
    name: RouteName.SPIKE_DEEPLINK,
    component: MagicLinkMobile,
    meta: {
      emailLink: true,
    },
  },
  {
    path: "/",
    alias: "/open",
    name: RouteName.MENU_MAIN,
    component: ContainerMain,
    beforeEnter: [signInWithUrlToken, questionnairesMandatoryGuard],
    children: mainMenuRoutes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:catchAll(.*)*",
    redirect: { name: RouteNameMenu.MENU_ANALYTICS },
  },
];
