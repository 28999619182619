<script setup lang="ts">
import { RouteName } from "@/types/AuthTypes";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<template>
  <div class="settings-container">
    <div class="tree-container">
      <div class="master-container">
        <RouterLink :to="{ name: RouteName.SETTINGS_PROFILE }" class="item-link">
          <div class="icon">
            <img src="@/assets/icons/settings-profile.svg" alt="settings-profile" />
          </div>
          <span>{{ t("SETTINGS.SECTION.PROFILE_INFO") }}</span>
        </RouterLink>

        <RouterLink :to="{ name: RouteName.SETTINGS_BIONIQ }" class="item-link">
          <div class="icon">
            <img src="@/assets/icons/settings-settings.svg" alt="settings-settings" />
          </div>
          <span>{{ t("SETTINGS.SECTION.ACCOUNT_SET") }}</span>
        </RouterLink>
        <a href="https://www.google.com/" target="_blank" class="item-link">
          <div class="icon">
            <img src="@/assets/icons/settings-bag.svg" alt="settings-profile" />
          </div>
          <span>{{ t("SETTINGS.SECTION.MANAGE_SUBSCRIPTION") }}</span>
        </a>
        <a href="https://www.google.com/" target="_blank" class="item-link">
          <div class="icon">
            <img src="@/assets/icons/settings-bag.svg" alt="settings-profile" />
          </div>
          <span>{{ t("SETTINGS.SECTION.REFER_FRIEND") }}</span>
        </a>
      </div>
    </div>
    <div class="subtree-container">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";
@import "@/assets/styles/ui.css";

.settings-container {
  display: flex;
  gap: 1em;
}
.tree-container {
  display: flex;
  column-gap: 1em;
}
.master-container {
  padding: 2em 1em;
  border: 1px solid var(--clr-grey-3);
  border-radius: 1em;
  overflow-y: overlay;
  flex: 1;
  background-color: var(--clr-white-1);
}
.subtree-container {
  box-sizing: border-box;
  padding: 1.5em;
  border: 1px solid var(--clr-grey-3);
  border-radius: 1em;
  overflow-y: overlay;
  flex: 4;
  background-color: var(--clr-white-1);
  max-height: calc(100vh - var(--layout-infobar-height) - (var(--layout-padding) * 2));
}
@mixin scrollbar-two .subtree-container, 15px, 0px, var(--clr-grey-3);
.item-link {
  box-sizing: border-box;
  text-decoration: none;
  color: var(--clr-black-1);
  padding: 0.5em;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  & span {
    font-size: 1.1rem;
  }
  & .icon {
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
    & img {
      width: 100%;
      height: 100%;
    }
  }
}
.item-link:not(:last-child) {
  margin-bottom: 1em;
}
.router-link-active.item-link {
  box-shadow: 0px 0px 0px 1px var(--clr-blue-bioniq-faded);
  border-radius: 0.5em;
}

@media (--max-phone-width) {
  .settings-container {
    display: block;
  }
  .master-container {
    padding: 1.5em 1em;
    margin-bottom: 1em;
  }
  .subtree-container {
    margin-bottom: 1em;
    max-height: none;
  }
}
</style>
