import { ref } from "vue";
import { computed } from "vue";
import { LegalLinks, DACH, CountryCode } from "@/util/constants";

export function useLegal() {
  const country = ref(navigator.language);

  const filteredCountry = computed(() => {
    const langCodeArray = country.value.split("-");

    if (langCodeArray.length === 1) {
      return langCodeArray[0].toUpperCase();
    } else if (langCodeArray.length > 1) {
      return langCodeArray[1].toUpperCase();
    }
    return "";
  });
  const isDachMarket = computed(() => {
    const includeSwitzerland = true;
    const includeNetherlands = true;
    return (
      filteredCountry.value === DACH.DE ||
      filteredCountry.value === DACH.AT ||
      (includeNetherlands && filteredCountry.value === DACH.NL) ||
      (includeSwitzerland && filteredCountry.value === DACH.CH)
    );
  });

  const legalLinksUrls = computed(() => {
    const country = filteredCountry.value as CountryCode;

    if (!LegalLinks[country] && !isDachMarket.value) {
      return {
        terms: LegalLinks[CountryCode.US].terms,
        privacy: LegalLinks[CountryCode.US].privacy,
      };
    }

    if (isDachMarket.value) {
      return {
        terms: LegalLinks[CountryCode.DE].terms,
        privacy: LegalLinks[CountryCode.DE].privacy,
      };
    }

    return {
      terms: LegalLinks[country].terms,
      privacy: LegalLinks[country].privacy,
    };
  });

  return { legalLinksUrls };
}
