<script setup lang="ts">
import SettingsDetailLayout from "@/components/layout/settings/DetailLayout.vue";
import { useRouter } from "vue-router";
import { RouteName } from "@/types/AuthTypes";
import ModalMain from "@/components/ui/ModalMain.vue";
import { useConfirmDialog } from "@vueuse/core";
import { useAuthStore } from "@/stores/auth";
import { useLegal } from "@/composables/useLegal";
import AlertAction from "@/components/layout/AlertAction.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const authStore = useAuthStore();
const { legalLinksUrls } = useLegal();
const { isRevealed, reveal, confirm, cancel } = useConfirmDialog();

const openLogoutModal = (e: Event) => {
  reveal();
  e.stopPropagation();
};

const logout = () => {
  confirm();
  authStore.logout();
  location.reload();
};
</script>

<template>
  <div class="bioniq-settings-container">
    <SettingsDetailLayout>
      <template v-slot:title>
        <span>Account</span>
      </template>
      <div class="simple-item" @click="router.push({ name: RouteName.AUTH_EMAIL_CHANGE })">
        <div class="icon">
          <img src="@/assets/icons/settings-email.svg" alt="change-email" />
        </div>
        <span>Change Email</span>
      </div>
      <div class="simple-item" @click="router.push({ name: RouteName.AUTH_EMAIL_FP })">
        <div class="icon">
          <img src="@/assets/icons/settings-password.svg" alt="change-password" />
        </div>
        <span>Change password</span>
      </div>
    </SettingsDetailLayout>
    <SettingsDetailLayout>
      <template v-slot:title>
        <span>General</span>
      </template>
      <div class="simple-item">
        <div class="icon">
          <img src="@/assets/icons/settings-app.svg" alt="app-settings" />
        </div>
        <span>Account Settings</span>
      </div>
      <div class="simple-item">
        <div class="icon">
          <img src="@/assets/icons/settings-terms.svg" alt="term-settings" />
        </div>
        <span>
          <a :href="legalLinksUrls.terms" target="_blank" data-link="terms">{{ t("QUESTIONNAIRES.TERMS_COND") }}</a>
        </span>
      </div>
      <div class="simple-item">
        <div class="icon">
          <img src="@/assets/icons/settings-data.svg" alt="data-settings" />
        </div>
        <span>
          <a :href="legalLinksUrls.privacy" target="_blank" data-link="terms">{{ t("QUESTIONNAIRES.DATA_PRIVACY") }}</a>
        </span>
      </div>
    </SettingsDetailLayout>
    <SettingsDetailLayout>
      <div class="simple-item" @click="openLogoutModal">
        <div class="icon">
          <img src="@/assets/icons/settings-logout.svg" alt="logout-account" />
        </div>
        <div class="simple-item danger">Log Out</div>
      </div>
      <ModalMain v-if="isRevealed" :modalType="'action'" @clickOutside="cancel">
        <AlertAction @yes="logout" @no="cancel" :type="'danger'">
          <template v-slot:title>Are you sure you want to log out ?</template>
          <template v-slot:yes>Yes</template>
          <template v-slot:no>No</template>
        </AlertAction>
      </ModalMain>
    </SettingsDetailLayout>
    <SettingsDetailLayout>
      <div class="simple-item">
        <div class="icon">
          <img src="@/assets/icons/settings-delete.svg" alt="delete-account" />
        </div>
        <div class="simple-item danger">Delete account</div>
      </div>
    </SettingsDetailLayout>
  </div>
</template>

<style scoped lang="pcss">
@import "@/assets/base.css";
& :deep(.modal-main) {
  height: 10em;
}
.simple-item {
  width: fit-content;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  & .icon {
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
    & img {
      width: 100%;
      height: 100%;
    }
  }
}
.simple-item:not(:last-child) {
  margin-bottom: 0.5em;
}
.simple-item.danger {
  color: var(--clr-red-5);
}
.simple-item.danger:hover {
  color: var(--clr-red-1);
}
.simple-item:hover {
  opacity: 0.7;
}
.row-with-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
  height: 2rem;
}
.row-with-action :deep(.switch-toggle-container) {
  transform: scale(0.8);
}

span a {
  text-decoration: none;
  cursor: pointer;
  &:visited {
    color: black;
  }
}
</style>
