export type LoginData = {
  email: string;
  password: string;
};

export type LDInputErrors = {
  email: boolean;
  password: boolean;
};

export enum RouteName {
  AUTH_EMAIL = "auth-email",
  AUTH_PASSWORD = "auth-password",
  AUTH_EMAIL_FP = "auth-email-fp",
  AUTH_EMAIL_SENT = "auth-email-sent",
  AUTH_EMAIL_CONFIRM = "auth-email-confirm",
  AUTH_EMAIL_CONFIRMING = "auth-email-confirming",
  AUTH_SIGNUP_CONFIRMING = "auth-signup-confirming",
  AUTH_EMAIL_CHANGE = "auth-email-change",
  AUTH_PASS_NEW = "auth-password-new",
  AUTH_ERROR_CONFIRM = "error-confirm",
  AUTH_SUCCESS_CONFIRM = "success-confirm",
  AUTH_ERROR_MAGIC = "error-magic",
  AUTH_SIGNING_IN = "sign-in",
  AUTH_SIGN_IN_CONFIRM = "sign-in-confirm",
  MENU_MAIN = "",
  QUESTIONNAIRE_MAIN = "questionnaires",
  QUESTIONNAIRE_SUPPLEMENTS = "questionnaire-supplements",
  QUESTIONNAIRE_LIFESTYLE = "questionnaire-lifestyle",
  QUESTIONNAIRE_ONBOARDING_CHATBOT = "questionnaire-onboarding-chatbot",
  QUESTIONNAIRE_FIRSTCLASSES_REC = "questionnaire-firstclasses-rec",
  QUESTIONNAIRE_ALGORITHM = "questionnaire-algorithm",
  BLOOD_CHECKUPS = "blood-checkups",
  BLOOD_PROGRESS = "blood-progress",
  SETTINGS_PROFILE = "profile",
  SETTINGS_BIONIQ = "bioniq-settings",
  SETTINGS_LOGOUT = "logout",
  OPEN_PAGE = "open-page",
  DOWNLOAD_MOBILE_APP = "download-mobile",
  SPIKE_DEEPLINK = "spike-deeplink",
}
export enum RouteNameMenu {
  MENU_ANALYTICS = "analytics",
  MENU_FORMULA = "formula",
  MENU_SETTINGS = "settings",
  MENU_MESSAGES = "messages",
}

export const RouteDisplayNames: { [key in RouteNameMenu]: string } = {
  analytics: "NAVBAR.ANALYTICS",
  formula: "NAVBAR.FORMULA",
  settings: "NAVBAR.SETTINGS",
  messages: "NAVBAR.MESSAGES",
};

export enum RouteActions {
  UPLOAD_BLOOD_TEST = "upload-test-result",
}
